import React from 'react';
import { Box, Typography } from '@mui/material';
import './IconSelector.css';

// Función para importar todas las imágenes de una carpeta de forma dinámica
const importAll = (r) => {
    let images = {};
    r.keys().forEach((item, index) => { 
        images[item.replace('./', '')] = r(item); 
    });
    return images;
};

// Importa todas las imágenes de la carpeta `assets/icons`
const localIcons = importAll(require.context('../../assets/icons', false, /\.(png|jpe?g|svg)$/));

// Importa todas las imágenes de la carpeta `assets/icons2`
const localIcons2 = importAll(require.context('../../assets/icons2', false, /\.(png|jpe?g|svg)$/));

// Íconos organizados por categoría
const iconCategories = {
  "Transportes": [
    'https://maps.google.com/mapfiles/kml/pal2/icon21.png',
    'https://maps.google.com/mapfiles/kml/pal2/icon7.png',
    'https://maps.google.com/mapfiles/kml/pal2/icon48.png',
    'https://maps.google.com/mapfiles/kml/pal2/icon39.png',
  ],
  "Lugares de Interés": [
    'https://maps.google.com/mapfiles/kml/pal2/icon11.png',
    'https://maps.google.com/mapfiles/kml/pal2/icon10.png',
    'https://maps.google.com/mapfiles/kml/pal2/icon0.png',
    'https://maps.google.com/mapfiles/kml/pal2/icon19.png',
    'https://maps.google.com/mapfiles/kml/pal2/icon32.png',
    'https://maps.google.com/mapfiles/kml/pal2/icon49.png',
    'https://maps.google.com/mapfiles/kml/pal2/icon52.png',
    'https://maps.google.com/mapfiles/kml/pal2/icon49.png',
    'https://maps.google.com/mapfiles/kml/pal2/icon4.png',
    'https://maps.google.com/mapfiles/kml/pal4/icon55.png',
    'https://maps.google.com/mapfiles/kml/pal2/icon54.png',
    'https://maps.google.com/mapfiles/kml/pal3/icon18.png',
  ],
  "marcadores": [
    ...Object.values(localIcons) // Agrega los íconos de `assets/icons`
  ],
  "marcadores 2": [
    ...Object.values(localIcons2) // Agrega los íconos de `assets/icons2`
  ]
};

const IconSelector = ({ onSelectIcon }) => {
  return (
    <Box className="icon-selector-container">
      {Object.keys(iconCategories).map((category) => (
        <Box key={category} mb={4}>
          <Typography variant="h6" gutterBottom>
            {category}
          </Typography>
          <div className="icon-grid">
            {iconCategories[category].map((icon, index) => (
              <img
                src={icon}
                alt={`icon-${index}`}
                className="icon-item"
                onClick={() => onSelectIcon(icon)} // Llamar a la función de selección
                key={index}
              />
            ))}
          </div>
        </Box>
      ))}
    </Box>
  );
};

export default IconSelector;
