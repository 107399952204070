import React from 'react';
import { Button } from '@mui/material';
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';
import * as XLSX from 'xlsx';
import './ExcelUploader.css'; // Asegúrate de importar el archivo CSS

function ExcelUploader({ setData }) {
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();

            reader.onload = (event) => {
                try {
                    const data = new Uint8Array(event.target.result);
                    const workbook = XLSX.read(data, { type: 'array' });

                    const sheetName = workbook.SheetNames[0];
                    const sheet = workbook.Sheets[sheetName];

                    if (!sheet) {
                        throw new Error('No se pudo leer la hoja del archivo');
                    }

                    const json = XLSX.utils.sheet_to_json(sheet);

                    if (json.length === 0) {
                        throw new Error('La hoja está vacía');
                    }

                    setData(json);
                } catch (error) {
                    console.error('Error al procesar el archivo:', error.message);
                    setData([]);
                }
            };

            reader.readAsArrayBuffer(file);
        }
    };

    return (
        <div>
            <input
                accept=".xlsx, .xls"
                style={{ display: 'none' }}
                id="file-upload"
                type="file"
                onChange={handleFileChange}
            />
            <label htmlFor="file-upload">
                <Button
                    variant="contained"
                    component="span"
                    id="excel-upload-button" // Cambiado a ID único
                    startIcon={<CloudUploadIcon />}
                >
                    Cargar Excel
                </Button>
            </label>
        </div>
    );
}

export default ExcelUploader;
