import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import './Header.css'; // Importa el archivo CSS
import logo from '../../assets/NE3_logo.svg'; // Ruta al logo

function Header() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const navigate = useNavigate();

    const handleLogin = () => {
        navigate('/login');
    };

    const handleLogout = () => {
        setIsAuthenticated(false);
        navigate('/');
    };

    return (
        <header className="header">
            <div className="logo-container">
                <img src={logo} alt="NE3 Logo" className="header-logo" />
            </div>
            <div className="header-icons">
                {isAuthenticated ? (
                    <IconButton color="inherit" onClick={handleLogout}>
                        <LogoutIcon />
                    </IconButton>
                ) : (
                    <IconButton color="inherit" onClick={handleLogin}>
                        <LoginIcon />
                    </IconButton>
                )}
            </div>
        </header>
    );
}

export default Header;
