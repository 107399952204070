import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Drawer } from '@mui/material';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import PlaceIcon from '@mui/icons-material/Place';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import { useNavigate, useLocation } from 'react-router-dom';
import './Sidebar.css'; // Importa el archivo CSS

const Sidebar = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const handleNavigation = (path) => {
        navigate(path);
    };

    return (
        <Drawer
            className="drawer" // Aplica la clase CSS para el fondo
            sx={{ width: 240, flexShrink: 0 }}
            variant="permanent"
            anchor="left"
        >
            <List>
                <ListItem
                    button
                    onClick={() => handleNavigation('/recorrido')}
                    className={location.pathname === '/recorrido' ? 'list-item selected' : 'list-item'}
                >
                    <ListItemIcon>
                        <DirectionsBusIcon />
                    </ListItemIcon>
                    <ListItemText primary="Recorrido de Buses" />
                </ListItem>
                <ListItem
                    button
                    onClick={() => handleNavigation('/marcadores-interes')}
                    className={location.pathname === '/marcadores-interes' ? 'list-item selected' : 'list-item'}
                >
                    <ListItemIcon>
                        <PlaceIcon />
                    </ListItemIcon>
                    <ListItemText primary="Marcadores de Interés" />
                </ListItem>
                <ListItem
                    button
                    onClick={() => handleNavigation('/marca-poligonos')}
                    className={location.pathname === '/marca-poligonos' ? 'list-item selected' : 'list-item'}
                >
                    <ListItemIcon>
                        <ZoomInMapIcon />
                    </ListItemIcon>
                    <ListItemText primary="Marca de Polígonos" />
                </ListItem>
                <ListItem
                    button
                    onClick={() => handleNavigation('/my-maps')}
                    className={location.pathname === '/my-maps' ? 'list-item selected' : 'list-item'}
                >
                    <ListItemIcon>
                        <ZoomInMapIcon />
                    </ListItemIcon>
                    <ListItemText primary="Crear My Maps" />
                </ListItem>
                <ListItem
                    button
                    onClick={() => handleNavigation('/my-maps-search')}
                    className={location.pathname === '/my-maps-search' ? 'list-item selected' : 'list-item'}
                >
                    <ListItemIcon>
                        <ZoomInMapIcon />
                    </ListItemIcon>
                    <ListItemText primary="Consultar My Maps" />
                </ListItem>
            </List>
        </Drawer>
    );
};

export default Sidebar;
