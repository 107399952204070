import React, { useState, useEffect } from 'react';
import { Button, TextField, Box, Snackbar, Alert } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SaveIcon from '@mui/icons-material/Save';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import * as XLSX from 'xlsx';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Header from '../../shared/header/Header';
import './FullMapPage.css'; // Importa el CSS específico para este componente

const MarcaPoligonos = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [zona, setZona] = useState('');
  const [excelCargado, setExcelCargado] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [map, setMap] = useState(null); // Guardar la instancia del mapa
  const [polygon, setPolygon] = useState(null); // Guardar la instancia del polígono

  useEffect(() => {
    if (window.google) {
      initMap();
    } else {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyD1Nl9yulU0jgl6VRGkK89neIalMrblrws&callback=initMap`;
      script.async = true;
      document.body.appendChild(script);
      window.initMap = initMap; // Asigna la función al objeto window
    }
  }, []);

  useEffect(() => {
    if (map) {
      fetchMapDetails();
    }
  }, [map]);

  const initMap = () => {
    const mapInstance = new window.google.maps.Map(document.getElementById('map'), {
      center: { lat: -34.391911, lng: -58.661421 },
      zoom: 12,
    });
    setMap(mapInstance);
  };

  const fetchMapDetails = async () => {
    try {
      const response = await axios.get(`https://mapane3.tipicaweb.com/api/api/getMap/${id}`);
      const mapData = response.data;

      // Dibujar líneas
      const linePath = mapData.lineas.map(line => ({ lat: line.lat, lng: line.lng }));
      new window.google.maps.Polyline({
        path: linePath,
        geodesic: true,
        strokeColor: '#FF0000',
        strokeOpacity: 1.0,
        strokeWeight: 2,
      }).setMap(map);

      // Dibujar polígonos
      const polygonPath = mapData.poligonos.map(polygon => ({ lat: polygon.lat, lng: polygon.lng }));
      new window.google.maps.Polygon({
        paths: polygonPath,
        strokeColor: '#0000FF',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#0000FF',
        fillOpacity: 0.35,
      }).setMap(map);

      // Dibujar puntos de interés
      mapData.puntosInteres.forEach(point => {
        new window.google.maps.Marker({
          position: { lat: point.lat, lng: point.lng },
          map: map,
          title: point.nombre || 'Punto de Interés',
        });
      });
    } catch (error) {
      console.error('Error fetching map details:', error);
      setAlertMessage('Error al cargar los detalles del mapa');
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        try {
          const data = new Uint8Array(event.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];

          if (!sheet) {
            throw new Error('No se pudo leer la hoja del archivo');
          }

          const json = XLSX.utils.sheet_to_json(sheet);

          if (json.length === 0) {
            throw new Error('La hoja está vacía');
          }

          setData(json);
          setExcelCargado(true);
          drawPolygon(json); // Dibuja el polígono en el mapa
        } catch (error) {
          console.error('Error al procesar el archivo:', error.message);
          setData([]);
          setExcelCargado(false);
          setAlertMessage('Error al cargar el archivo');
          setAlertSeverity('error');
          setAlertOpen(true);
        }
      };

      reader.readAsArrayBuffer(file);
    }
  };

  const drawPolygon = (data) => {
    if (map && data.length > 0) {
      const coordinates = data.map(row => ({
        lat: parseFloat(row.lat),
        lng: parseFloat(row.lng),
      }));

      // Si ya existe un polígono en el mapa, lo elimina antes de dibujar uno nuevo
      if (polygon) {
        polygon.setMap(null);
      }

      // Crear el polígono con las coordenadas del archivo Excel
      const newPolygon = new window.google.maps.Polygon({
        paths: coordinates,
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.35,
        draggable: false, // No permite arrastrar el polígono
        editable: false,  // No permite editar el polígono
      });

      newPolygon.setMap(map);
      setPolygon(newPolygon);
    }
  };

  const handleSave = () => {
    if (!zona || data.length === 0) {
      setAlertMessage('Zona o datos faltantes');
      setAlertSeverity('error');
      setAlertOpen(true);
      return;
    }

    axios.post('https://mapane3.tipicaweb.com/api/api/savePolygon', {
      nombre_zona: zona,
      puntos: data,
    })
      .then((response) => {
        setAlertMessage('Datos guardados exitosamente');
        setAlertSeverity('success');
        setAlertOpen(true);

        // Limpiar el contenedor del archivo y el campo de entrada
        setData([]);
        setExcelCargado(false);
        setZona('');
        document.getElementById('file-upload').value = ''; // Limpiar el input de archivo
        if (polygon) {
          polygon.setMap(null); // Limpiar el polígono del mapa
          setPolygon(null);
        }
      })
      .catch((error) => {
        setAlertMessage('Error al guardar los datos');
        setAlertSeverity('error');
        setAlertOpen(true);
      });
  };

  return (
    <div className="container">
      <Header />
      <div id="map" className="map"></div>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={() => setAlertOpen(false)}
      >
        <Alert onClose={() => setAlertOpen(false)} severity={alertSeverity}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default MarcaPoligonos;