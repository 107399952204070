import React, { useState } from 'react';
import { Button, Popover } from '@mui/material';
import { SwatchesPicker } from 'react-color';
import BrushIcon from '@mui/icons-material/Brush'; // Importar el ícono
import './ColorPicker.css';

const ColorPicker = ({ selectedColor, onChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleColorChange = (color) => {
    onChange(color.hex);
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'color-popover' : undefined;

  return (
    <div>
      <Button
        variant="contained"
        onClick={handleClick}
        id="color-picker-button"
        startIcon={<BrushIcon />} // Agregar el ícono al botón
      >
        Color de línea
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <SwatchesPicker color={selectedColor} onChangeComplete={handleColorChange} />
      </Popover>
    </div>
  );
};

export default ColorPicker;
