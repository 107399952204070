import React, { useState, useEffect } from 'react';
import { Button, TextField, Box, Snackbar, Alert } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SaveIcon from '@mui/icons-material/Save';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import * as XLSX from 'xlsx';
import axios from 'axios';
import PoligonColorPicker from '../poligonosColor/poligonColorPicker'; // Asegúrate de importar el componente de color
import './marcaPoligonos.css';

const MarcaPoligonos = () => {
  const [data, setData] = useState([]);
  const [zona, setZona] = useState('');
  const [color, setColor] = useState('#FF0000'); // Estado para almacenar el color seleccionado
  const [excelCargado, setExcelCargado] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [map, setMap] = useState(null);
  const [polygon, setPolygon] = useState(null);

  useEffect(() => {
    if (window.google) {
      initMap();
    } else {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyD1Nl9yulU0jgl6VRGkK89neIalMrblrws&callback=initMap`;
      script.async = true;
      document.body.appendChild(script);
      window.initMap = initMap;
    }
  }, []);

  const initMap = () => {
    const mapInstance = new window.google.maps.Map(document.getElementById('map'), {
      center: { lat: -34.391911, lng: -58.661421 },
      zoom: 12,
    });
    setMap(mapInstance);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        try {
          const data = new Uint8Array(event.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          if (!sheet) {
            throw new Error('No se pudo leer la hoja del archivo');
          }
          const json = XLSX.utils.sheet_to_json(sheet);
          if (json.length === 0) {
            throw new Error('La hoja está vacía');
          }
          setData(json);
          setExcelCargado(true);
          drawPolygon(json, color); // Dibuja el polígono con el color seleccionado
        } catch (error) {
          setData([]);
          setExcelCargado(false);
          setAlertMessage('Error al cargar el archivo');
          setAlertSeverity('error');
          setAlertOpen(true);
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const drawPolygon = (data, selectedColor) => {
    if (map && data.length > 0) {
      const coordinates = data.map(row => ({
        lat: parseFloat(row.lat),
        lng: parseFloat(row.lng),
      }));

      if (polygon) {
        polygon.setMap(null);
      }

      const newPolygon = new window.google.maps.Polygon({
        paths: coordinates,
        strokeColor: selectedColor,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: selectedColor,
        fillOpacity: 0.35,
        draggable: false,
        editable: false,
      });

      newPolygon.setMap(map);
      setPolygon(newPolygon);
    }
  };

  const handleSave = () => {
    if (!zona || data.length === 0) {
      setAlertMessage('Zona o datos faltantes');
      setAlertSeverity('error');
      setAlertOpen(true);
      return;
    }

    axios.post('https://mapane3.tipicaweb.com/api/api/savePolygon', {
      nombre_zona: zona,
      puntos: data,
      color: color, // Se envía el color seleccionado junto con los datos
    })
      .then(() => {
        setAlertMessage('Datos guardados exitosamente');
        setAlertSeverity('success');
        setAlertOpen(true);
        setData([]);
        setExcelCargado(false);
        setZona('');
        document.getElementById('file-upload').value = '';
        if (polygon) {
          polygon.setMap(null);
          setPolygon(null);
        }
      })
      .catch(() => {
        setAlertMessage('Error al guardar los datos');
        setAlertSeverity('error');
        setAlertOpen(true);
      });
  };

  return (
    <div className="container">
      <Box className="file-controls">
        <input
          accept=".xlsx, .xls"
          style={{ display: 'none' }}
          id="file-upload"
          type="file"
          onChange={handleFileChange}
        />
        <label htmlFor="file-upload">
          <Button
            variant="contained"
            component="span"
            startIcon={<CloudUploadIcon />}
            id="excel-upload-button" // Agregar ID aquí
          >
            Cargar Excel
          </Button>
        </label>
        {excelCargado && (
          <Box display="flex" alignItems="center">
            <CheckCircleIcon color="success" />
            <span>Archivo Excel cargado</span>
          </Box>
        )}
        <TextField
          placeholder="Zona"
          value={zona}
          onChange={(e) => setZona(e.target.value)}
          className="custom-input"
        />
        <PoligonColorPicker
          selectedColor={color}
          onChange={(newColor) => {
            setColor(newColor); // Actualiza el color seleccionado
            if (excelCargado) {
              drawPolygon(data, newColor); // Redibuja el polígono con el nuevo color
            }
          }}
        />
        <Button
          variant="contained"
          startIcon={<SaveIcon />}
          onClick={handleSave}
          id="save-button" // Agregar ID aquí
        >
          Guardar
        </Button>
      </Box>
      <div id="map" className="map"></div>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={() => setAlertOpen(false)}
      >
        <Alert onClose={() => setAlertOpen(false)} severity={alertSeverity}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default MarcaPoligonos;
