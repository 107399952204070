import React, { useEffect } from 'react';

const MapWithMarkers = ({ data, iconoSeleccionado }) => {
    useEffect(() => {
        const initMap = () => {
            const center = new window.google.maps.LatLng(-34.603722, -58.381592);
            const map = new window.google.maps.Map(document.getElementById('map'), {
                zoom: 9,
                center: center,
            });

            // Coloca los marcadores en el mapa con el ícono seleccionado
            data.forEach((point) => {
                new window.google.maps.Marker({
                    position: { lat: parseFloat(point.lat), lng: parseFloat(point.lng) },
                    map: map,
                    title: point.nombre || 'Sin nombre', // Nombre del punto
                    icon: iconoSeleccionado || undefined, // Usa el ícono seleccionado o el predeterminado
                });
            });
        };

        if (window.google) {
            initMap();
        } else {
            window.initMap = initMap;
        }
    }, [data, iconoSeleccionado]); // Ejecuta el efecto cuando cambien data o iconoSeleccionado

    return <div id="map" className="map"></div>;
};

export default MapWithMarkers;
