import React, { useState, useEffect } from 'react';
import { Button, TextField, Box, Snackbar, Alert, List, ListItem, ListItemText, Dialog, DialogActions, DialogContent, DialogTitle, Checkbox, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import axios from 'axios';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './mymaps.css'; // Importa el CSS específico para este componente

const Mymaps = () => {
  const [buses, setBuses] = useState([]);
  const [puntosInteres, setPuntosInteres] = useState([]);
  const [poligonos, setPoligonos] = useState([]);
  const [selectedElements, setSelectedElements] = useState([]); // Cambiado para manejar múltiples selecciones
  const [mapName, setMapName] = useState('');
  const [saveMapOpen, setSaveMapOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [map, setMap] = useState(null);

  useEffect(() => {
    if (window.google) {
      initMap(); 
    } else {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyD1Nl9yulU0jgl6VRGkK89neIalMrblrws&callback=initMap`;
      script.async = true;
      document.body.appendChild(script);
      window.initMap = initMap;
    }

    // Load data from the database
    axios.get('https://mapane3.tipicaweb.com/api/api/getLineas').then(response => setBuses(response.data));
    axios.get('https://mapane3.tipicaweb.com/api/api/getPuntosInteres').then(response => setPuntosInteres(response.data));
    axios.get('https://mapane3.tipicaweb.com/api/api/getPolygons').then(response => setPoligonos(response.data));
  }, []);

  const initMap = () => {
    const mapInstance = new window.google.maps.Map(document.getElementById('map'), {
      center: { lat: -34.391911, lng: -58.661421 },
      zoom: 12,
    });
    setMap(mapInstance);
  };

  const handleSelectElement = (element, type) => {
    const newElement = { ...element, type };
    const isSelected = selectedElements.some(el => el.id === newElement.id && el.type === newElement.type);
    
    if (isSelected) {
      setSelectedElements(selectedElements.filter(el => el.id !== newElement.id || el.type !== newElement.type));
      removeElementFromMap(newElement);
    } else {
      setSelectedElements([...selectedElements, newElement]);
      drawElementOnMap(newElement);
    }
  };

  const drawElementOnMap = (element) => {
    // Lógica para dibujar el elemento en el mapa según su tipo
  };

  const removeElementFromMap = (element) => {
    // Lógica para eliminar el elemento del mapa
  };

  const openSaveMapPopup = () => {
    setSaveMapOpen(true);
  };

  const closeSaveMapPopup = () => {
    setSaveMapOpen(false);
  };

  const handleSaveMap = () => {
    // Logic to save the map to the database
    axios.post('https://mapane3.tipicaweb.com/api/api/saveMap', { name: mapName, data: selectedElements ? [selectedElements] : [] })
      .then(response => {
        setAlertMessage('Mapa guardado exitosamente');
        setAlertSeverity('success');
        setAlertOpen(true);
        closeSaveMapPopup();
      })
      .catch(error => {
        setAlertMessage('Error al guardar el mapa');
        setAlertSeverity('error');
        setAlertOpen(true);
      });
  };

  return (
    <Box>
      <List>
        {/* Accordion para Puntos de Interés */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <ListItemText primary="Puntos de Interés" />
          </AccordionSummary>
          <AccordionDetails>
            <List component="div" disablePadding>
              {puntosInteres.map(punto => (
                <ListItem key={punto.id} button onClick={() => handleSelectElement(punto, 'punto')}>
                  <Checkbox
                    edge="start"
                    checked={selectedElements.some(el => el.id === punto.id && el.type === 'punto')}
                    tabIndex={-1}
                    disableRipple
                  />
                  <ListItemText primary={punto.nombre} />
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>

        {/* Líneas con Checkboxes */}
        <ListItem>
          <ListItemText primary="Líneas" />
        </ListItem>
        {buses.map(bus => (
          <ListItem key={bus.id} button onClick={() => handleSelectElement(bus, 'linea')}>
            <Checkbox
              edge="start"
              checked={selectedElements.some(el => el.id === bus.id && el.type === 'linea')}
              tabIndex={-1}
              disableRipple
            />
            <ListItemText primary={`Línea ${bus.linea}`} />
          </ListItem>
        ))}

        {/* Accordion para Polígonos */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <ListItemText primary="Polígonos" />
          </AccordionSummary>
          <AccordionDetails>
            <List component="div" disablePadding>
              {poligonos.map(poligono => (
                <ListItem key={poligono.id} button onClick={() => handleSelectElement(poligono, 'poligono')}>
                  <Checkbox
                    edge="start"
                    checked={selectedElements.some(el => el.id === poligono.id && el.type === 'poligono')}
                    tabIndex={-1}
                    disableRipple
                  />
                  <ListItemText primary={poligono.nombre_zona} />
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      </List>

      <Button variant="contained" startIcon={<SaveIcon />} onClick={openSaveMapPopup}>
        Guardar Mapa
      </Button>
      <Dialog open={saveMapOpen} onClose={closeSaveMapPopup}>
        <DialogTitle>Guardar Mapa</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Nombre del Mapa"
            fullWidth
            value={mapName}
            onChange={(e) => setMapName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeSaveMapPopup}>Cancelar</Button>
          <Button onClick={handleSaveMap}>Guardar</Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={alertOpen} autoHideDuration={6000} onClose={() => setAlertOpen(false)}>
        <Alert onClose={() => setAlertOpen(false)} severity={alertSeverity}>
          {alertMessage}
        </Alert>
      </Snackbar>
      <div id="map" style={{ height: '500px', width: '100%' }}></div>
    </Box>
  );
};

export default Mymaps;