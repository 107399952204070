import React, { useState, useEffect } from 'react';
import './searchMyMapas.css';

const SearchMyMapas = () => {
    const [maps, setMaps] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const fetchMaps = async () => {
            try {
                const response = await fetch('https://mapane3.tipicaweb.com/api/api/getAllMaps');
                const data = await response.json();
                setMaps(data);
            } catch (error) {
                console.error('Error fetching maps:', error);
            }
        };

        fetchMaps();
    }, []);

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredMaps = maps.filter(map =>
        map.map_name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="container">
            <header className="header">
                <h1>Mapas</h1>
            </header>
            <input
                type="text"
                placeholder="Buscador..."
                value={searchTerm}
                onChange={handleSearch}
                className="search-input"
            />
            <table className="maps-table">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Map Name</th>
                        <th>URL</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredMaps.map(map => (
                        <tr key={map.id}>
                            <td>{map.id}</td>
                            <td>{map.map_name}</td>
                            <td><a href={map.url}>{map.url}</a></td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default SearchMyMapas;