import React, { useState } from 'react';
import { Button, TextField, Box, Alert, Snackbar, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import IconSelector from '../commons/IconSelector';
import axios from 'axios';
import ExcelUploader from '../../Upload-excel';
import MapWithMarkers from '../commons/MapWithMarkers';
import './marcadoresInteres.css';

const MarcadoresInteres = () => {
    const [data, setData] = useState([]);
    const [nombre, setNombre] = useState('');
    const [excelCargado, setExcelCargado] = useState(false);
    const [iconoSeleccionado, setIconoSeleccionado] = useState('');
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('success');
    const [dialogOpen, setDialogOpen] = useState(false); // Estado para el modal

    const handleSave = () => {
        if (!nombre || data.length === 0) {
            setAlertMessage('Nombre o datos faltantes');
            setAlertSeverity('error');
            setAlertOpen(true);
            return;
        }
    
        axios.post('https://mapane3.tipicaweb.com/api/api/savePuntoInteres', {
            nombre,
            data,
            icono: iconoSeleccionado, // Guardar el ícono seleccionado
        })
            .then((response) => {
                setAlertMessage('Datos guardados exitosamente');
                setAlertSeverity('success');
                setAlertOpen(true);
    
                // Limpiar los campos después de guardar
                setNombre(''); 
                setData([]);
                setIconoSeleccionado('');
                setExcelCargado(false);
            })
            .catch((error) => {
                setAlertMessage('Error al guardar los datos');
                setAlertSeverity('error');
                setAlertOpen(true);
            });
    };

    const handleExcelUpload = (jsonData) => {
        setData(jsonData);
        setExcelCargado(true);
    };

    const handleIconSelection = (icon) => {
        setIconoSeleccionado(icon); // Actualizar el ícono seleccionado
        setDialogOpen(false); // Cerrar el diálogo
    };

    return (
        <div className="container">
            <Box className="file-controls">
                <ExcelUploader setData={handleExcelUpload} />
                {excelCargado && (
                    <Box display="flex" alignItems="center" className="file-upload-message">
                        <CheckCircleIcon color="success" />
                        <span>Archivo Excel cargado</span>
                    </Box>
                )}
                <TextField
                    placeholder="Nombre"
                    id="marcadores-custom-input"
                    value={nombre}
                    onChange={(e) => setNombre(e.target.value)}
                />
                <Button
                    variant="contained"
                    startIcon={<SaveIcon />}
                    id="marcadores-custom-button"
                    onClick={handleSave}
                >
                    Guardar
                </Button>

                {/* Botón para seleccionar ícono */}
                <Button
                    id="marcadores-icon-button" // ID para aplicar estilos
                    variant="contained"
                    onClick={() => setDialogOpen(true)}
                >
                    Seleccionar Ícono
                </Button>



                {/* Mostrar ícono seleccionado */}
                {iconoSeleccionado && (
                    <Box display="flex" alignItems="center">
                        <img src={iconoSeleccionado} alt="Ícono seleccionado" style={{ width: 32, height: 32 }} />
                    </Box>
                )}
            </Box>

            {/* Aquí se pasa el iconoSeleccionado al componente MapWithMarkers */}
            <MapWithMarkers id="marcadores-map" data={data} iconoSeleccionado={iconoSeleccionado} />

            {/* Modal para seleccionar ícono */}
            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
                <DialogTitle>Seleccionar Ícono</DialogTitle>
                <DialogContent>
                    <IconSelector onSelectIcon={handleIconSelection} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogOpen(false)}>Cerrar</Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={alertOpen}
                autoHideDuration={6000}
                onClose={() => setAlertOpen(false)}
            >
                <Alert onClose={() => setAlertOpen(false)} severity={alertSeverity}>
                    {alertMessage}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default MarcadoresInteres;

